import React from 'react';
import { Link } from 'gatsby';

import styles from './main-menu.module.scss';

export const mainMenuItems = [
  {
    'path': '/pharmacology',
    'text': 'Pharmacology and MOA'
  },
  {
    'path': '/efficacy',
    'text': 'Efficacy'
  },
  {
    'path': '/safety',
    'text': 'Safety'
  },
  {
    'path': '/dosing-and-administration',
    'text': 'Dosing and Administration'
  },
  {
    'path': '/resources',
    'text': 'Resources and Reimbursement'
  },
  {
    'path': '/order',
    'text': 'Ordering Info'
  }
];

const MainMenu = () => {
  return (
    <ul className={`${styles.mainMenu} hidden-mobile`}>
      {mainMenuItems.map((link, i) => (
        <li key={i} className={styles.mainMenuItem}>
          <Link
            className={styles.mainMenuItemLink}
            to={link.path}
            activeClassName={styles.mainMenuItemLinkActive}
            partiallyActive={true}
          >
            {link.text}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default MainMenu;
