import React from 'react';
import { Link } from 'gatsby';
import SearchToggle from '~components/search-toggle/search-toggle.component';

import styles from './top-menu.module.scss';

const ListLink = ({ type, path, children }) => {
  const renderLink = () => {
    switch (type) {
      case 'ext':
        return <a href={path} target="_blank" rel="noreferrer">{children}</a>;

      case 'hash':
        return <a href={path}>{children}</a>;

      default:
        return <Link to={path}>{children}</Link>;

    }
  }

  return (
    <li className={styles.menuItem}>
      {renderLink()}
    </li>
  );
}

export const topMenuItems = [
  {
    'type': 'ext',
    'path': 'https://uspi.byfavo.com',
    'text': 'Prescribing Information'
  },
  {
    'type': 'hash',
    'path': '#isi',
    'text': 'Important Safety Information and Boxed Warning'
  },
  {
    'path': '/request-conversation',
    'text': 'Schedule a Conversation'
  }
];

const TopMenu = () => (
  <ul>
    {topMenuItems.map((link, i) => (
      <ListLink key={i} {...link}>
        {link.text}
        {link.icon && <i className={link.icon}></i>}
      </ListLink>
    ))}
    <li className={styles.menuItem}>
      <SearchToggle />
    </li>
  </ul>
);

export default TopMenu;
