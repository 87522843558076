import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import styles from './logo.module.scss';

const altText = 'BYFAVO (amisulpride) logo';

const renderImage = ({ logo }) => {
  if (!logo.childImageSharp && logo.extension === 'svg') {
    return <img alt={altText} src={logo.publicURL} />
  }
  return <Img alt={altText} fluid={logo.childImageSharp.fluid} />
};

export default () => {
  const data = useStaticQuery(
    graphql`
      {
        logo: file(relativePath: { eq: "Byfavo-logo.svg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
      }
    `
  );

  return (
    <a className={styles.logo} href="/">
      {renderImage(data)}
    </a>
  )
}
