import React from 'react';
import { Link } from 'gatsby';
import { topMenuItems } from '~components/top-menu/top-menu.component';
import { mainMenuItems } from '~components/main-menu/main-menu.component';

import styles from './mobile-menu.module.scss';

export default () => {
  return (
    <div className={`${styles.mobileMenu} toggle-menu hidden-desktop`}>
      <ul className={styles.mobileMenuTop}>
        {topMenuItems.map((link, i) => (
          <li
            key={i}
            {...(link.helperClass && {className: link.helperClass})}
          >
            <a href={link.path}>{link.text}</a>
          </li>
        ))}
      </ul>
      <ul className={styles.mobileMenuMain}>
        {mainMenuItems.map((link, i) => (
          <li key={i}>
            <Link to={link.path}>{link.text}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
