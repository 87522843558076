import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import {graphql, useStaticQuery} from 'gatsby';
import { useLunr } from 'react-lunr';
import { searchUpdateQuery, searchUpdateResults } from '~state/search';

import './search-form.scss';

// Use a class based component to gain access to Component level events.
class SearchFormClass extends Component {
  componentDidUpdate() {
    this.handleSearchResults();
  }

  handleSearchResults = () => {
    const { results, searchResults, dispatch } = this.props;
    if (!_.isEqual(_.sortBy(results), _.sortBy(searchResults))) {
      dispatch(searchUpdateResults(results));
    }
  }

  onSearch = event => {
    this.props.dispatch(searchUpdateQuery(
        // Filter special characters that may break search.
        // ^[\W\s]* - starts with one or more non-word characters or whitespaces,
        // [;:^]    - list of disallowed to use characters.
        event.target.value.replace(/^[\W\s]*|[;:^]*/gi, '')
    ));
  }

  handleSubmit = event => {
    event.preventDefault();
    navigate(`/search`);
  }

  render() {
    const { onSearch, handleSubmit} = this;
    const { searchQuery } = this.props;

    return (
      <form className="search-form" onSubmit={handleSubmit}>
        <input
          aria-label="Search"
          className="search-input"
          type="text"
          onChange={onSearch}
          value={searchQuery}
          placeholder={'Search...'}
        />
      </form>
    );
  }
}

// Use a function based component to gain access to React hooks like "useStaticQuery" & "useLunr".
const SearchForm = props => {
  const data = useStaticQuery(
    graphql`
    {
      localSearchPages {
        store
        index
      }
    }
  `
  );
  const { searchQuery } = props;
  const index = JSON.parse(data.localSearchPages.index);
  const store = JSON.parse(data.localSearchPages.store);
  const results = useLunr(searchQuery, index, store);

  return (
    <SearchFormClass results={results} {...props} />
  );
};

const mapStateToProps = ({search: {searchQuery, searchResults}}) => {
  return {
    searchQuery,
    searchResults,
  }
};

export default connect(mapStateToProps)(SearchForm);
